// custom typefaces
import "./static/font/inter.css"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// Authentication
import "firebase/auth"

import React from "react"
import AuthProvider from "./src/context/auth"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
